import React from 'react'
import type { GatsbyBrowser } from 'gatsby'

import { sendAnalyticsEvent } from '@faststore/sdk'
import {
  NpmAnalyticsHandler,
  NpmErrorBoundary,
  NpmLocalStorageProvider,
  NpmTestProvider,
  NpmUIProvider,
  NpmHandleUtmParams,
} from 'src/local_modules'
import { Helmet } from 'react-helmet-async'
import { Layout } from './src/Layout'
import { metaTags } from './store.config'

import './src/styles/fonts.css'
import './src/styles/global.scss'
import './src/styles/layout.scss'
import './src/styles/theme.scss'

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => (
  <NpmErrorBoundary>
    <NpmAnalyticsHandler />
    <NpmTestProvider>
      <NpmUIProvider>
        <NpmLocalStorageProvider>{element}</NpmLocalStorageProvider>
      </NpmUIProvider>
    </NpmTestProvider>
  </NpmErrorBoundary>
)

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
}) => {
  return (
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content={metaTags['google-site-verification']}
        />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="anonymous"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Layout>{element}</Layout>
    </>
  )
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && prevLocation) {
    sendAnalyticsEvent<any>({
      name: 'pageView',
      params: {
        location: location.href,
        page: location.pathname,
        referrer: prevLocation.href,
        title: document.title,
        originalLocation: location.href,
        originalReferrer: prevLocation.href,
      },
    })
  }

  NpmHandleUtmParams()
}

export const onInitialClientRender = () => {
  sendAnalyticsEvent<any>({
    name: 'pageView',
    params: {
      location: window.location.href,
      page: window.location.pathname,
      referrer: '',
      title: document.title,
      originalLocation: window.location.href,
      originalReferrer: '',
    },
  })
}
